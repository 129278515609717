import { NavLink } from "react-router-dom";
import NavItemContainer from "./NavItemContainer";
import Logo from "../../../static/logo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Navbar(props) {
  const items = [
    { to: "/", text: "דף הבית" },
    { to: "/posts", text: "פוסטים" },
    { to: "/new-students", text: "תלמידים חדשים" },
    { to: "/gallery", text: "גלריה" },
    { to: "/grades", text: "דפים שכבתיים" },
  ];

  const bgColor = props.stillColor ? "#e3e2df" : "#dbdad6";

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light nav-gs heebo fixed-top"
      style={{ backgroundColor: bgColor }}
    >
      <div className="container-lg">
        <NavLink className="navbar-brand navbar-logo" to="/">
          <img style={{ maxHeight: 34 }} src={Logo} alt="Logo"></img>
        </NavLink>

        <button
          className="navbar-toggler me-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsedNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ outline: 0, border: 0, boxShadow: "0 0 0 0" }}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <NavItemContainer items={items} />
      </div>
    </nav>
  );
}

export default Navbar;
