import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faWaze,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faMapMarker,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <footer className="text-center text-lg-start text-dark footer">
      <div className="container p-4 pb-0">
        <section className="">
          <div className="row rtl text-end">
            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">
                תיכון גבעת שמואל
              </h6>
              <p>תיכון ממלכתי שש שנתי גבעת שמואל</p>
            </div>

            <hr className="w-100 clearfix d-md-none" />

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">
                קישורים חשובים
              </h6>
              <p>
                <a href="https://teachers.gsh.org.il" className="">
                  אתר המורים
                </a>
              </p>
              <p>
                <a href="emergencylearning">למידה בחירום</a>
              </p>
              <p>
                <a href="posts">פוסטים ועדכונים</a>
              </p>
            </div>

            <hr className="w-100 clearfix d-md-none" />
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">צור קשר</h6>
              <p className="m-3 me-0">
                <FontAwesomeIcon
                  icon={faMapMarker}
                  className="ms-2"
                  style={{ color: "red" }}
                />
                רח' גולדה מאיר 2, גבעת שמואל
              </p>
              <p className="m-3 me-0">
                <a
                  className="text-dark"
                  href="https://ul.waze.com/ul?preview_venue_id=22872385.228396167.356725&navigate=yes"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faWaze}
                    className="ms-2"
                    style={{ color: "#000" }}
                  />
                  ניווט דרך Waze
                </a>
              </p>
              <p className="m-3 me-0">
                <a href="mailto:office@gsh.edum.org.il" className="text-dark">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="ms-2"
                    style={{ color: "#1f59ab" }}
                  />
                  office@gsh.edum.org.il
                </a>
              </p>
              <p className="m-3 me-0">
                <a href="tel:037307631" className="text-dark">
                  <FontAwesomeIcon
                    icon={faPhone}
                    className="ms-2"
                    style={{ color: "#32a852" }}
                  />
                  03-7307631
                </a>
              </p>
            </div>
          </div>
        </section>

        <hr className="my-3" />

        <section className="p-3 pt-0">
          <div className="row d-flex align-items-center">
            <div className="col-md-7 col-lg-8 text-center text-md-start">
              <div className="p-3">
                © 2022 כל הזכויות שמורת לתיכון גבעת שמואל
              </div>
            </div>

            <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
              <a
                className="btn btn-outline-dark btn-floating m-1"
                role="button"
                href="https://www.facebook.com/tihongivatsmuel/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>

              <a
                className="btn btn-outline-dark btn-floating m-1"
                role="button"
                href="https://www.youtube.com/channel/UCAku4dqGpLgcODz3Wbm7IWQ"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
