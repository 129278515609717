import "../../css/not-found.css";

function Error(props) {
  return (
    <div className="vh-center">
      <p className="display-2 text-center">אירעה שגיאה</p>
      <p className="heebo rtl text-center">
        אופס, משהו לא טוב קרה. זה כנראה לא באשמתך!
        <br />
        אנא בדוק אם יש לך חיבור יציב לרשת.
        <br />
        אם הבעיה ממשיכה, פנה אלינו במייל <em>office@gsh.edum.org.il</em>
      </p>

      {/* <Link
        to="/"
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%)",
        }}
      >
        <button
          className="mt-3 btn btn-dark heebo"
          style={{ width: "fit-content" }}
        >
          בחזרה לאתר
        </button>
      </Link> */}
    </div>
  );
}

export default Error;
