import "../../../css/grades.css";
import { useEffect, useState } from "react";

/**
 * dayOfWeek - the day of the week 1-7
 */
function getDateOfWeek(dayOfWeek) {
  var curr = new Date();
  var dateWeek = new Date(
    curr.setDate(curr.getDate() - curr.getDay() + (dayOfWeek - 1))
  );
  return dateWeek.getDate() + "." + (dateWeek.getMonth() + 1);
}
function isNull(arr) {
  return arr.join().replace(/,/g, "").length === 0;
}

function getDayFromDate(dateText) {
  let date = new Date(Number(dateText.substring(6, dateText.length - 2)));
  let day = date.getDay();
  return isDateInThisWeek(date) ? day : -1;
}

function isDateInThisWeek(date) {
  const todayObj = new Date();
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay();

  // get first date of week
  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

  // get last date of week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  // if date is equal or within the first and last dates of the week
  return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

function Schedule(props) {
  const [schedule, setSchedule] = useState();
  const [firstClass, setFirstClass] = useState();

  useEffect(() => {
    let copySchedule = props.schedule;
    copySchedule = copySchedule.sort((a, b) => a.Day - b.Day);

    let result = [];
    let lowest = copySchedule[0].Hour;
    for (let i = 0; i < copySchedule.length; i++) {
      if (copySchedule[i].Hour < lowest) {
        lowest = copySchedule[i].Hour;
      }
      if (!result[copySchedule[i].Hour]) result[copySchedule[i].Hour] = [];
      result[copySchedule[i].Hour][copySchedule[i].Day] = copySchedule[i];
    }
    setFirstClass(lowest);
    if (!result) return;
    for (let i = 0; i < result.length; i++) {
      if (!result[i]) result[i] = [];
      for (let j = 0; j < 6; j++) {
        if (!result[i][j]) result[i][j] = null;
      }
    }

    if (result) {
      if (isNull(result[0])) result = result.slice(1);
    }

    console.log(copySchedule);

    setSchedule(result);
  }, [props.schedule, firstClass]);
  if (!schedule) return <p>Loading...</p>;
  let i = firstClass - 1,
    j = 0;
  return (
    <div className="blue-box rtl overflow-auto">
      <table className="table">
        <thead>
          <tr>
            <th className="title-box">
              <p className="title-in-box">שעה</p>
            </th>
            <th>
              <p className="title-in-box">יום ראשון</p>
              <p className="date-in-box">{getDateOfWeek(1)}</p>
            </th>
            <th className="title-box">
              <p className="title-in-box">יום שני</p>
              <p className="date-in-box">{getDateOfWeek(2)}</p>
            </th>
            <th className="title-box">
              <p className="title-in-box">יום שלישי</p>
              <p className="date-in-box">{getDateOfWeek(3)}</p>
            </th>
            <th className="title-box">
              <p className="title-in-box">יום רביעי</p>
              <p className="date-in-box">{getDateOfWeek(4)}</p>
            </th>
            <th className="title-box">
              <p className="title-in-box">יום חמישי</p>
              <p className="date-in-box">{getDateOfWeek(5)}</p>
            </th>
            <th className="title-box">
              <p className="title-in-box">יום שישי</p>
              <p className="date-in-box">{getDateOfWeek(6)}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {schedule.map((hour) => {
            i++;

            return (
              <tr key={i}>
                {<td>{i}</td>}
                {hour.map((currClass) =>
                  currClass ? (
                    <td style={{ whiteSpace: "pre" }} key={j++}>
                      {props.exams ? (
                        props.exams
                          .filter(
                            (exam) =>
                              getDayFromDate(exam.Date) === currClass.Day &&
                              exam.FromHour <= currClass.Hour &&
                              exam.ToHour >= currClass.Hour
                          )
                          .map((exam) => {
                            return (
                              <p
                                style={{ margin: 0 }}
                                className="exam-text text-end exam-box"
                                key={exam.Name + ":" + currClass.Hour}
                              >
                                {exam.Name + ", בחדר " + exam.Room}
                              </p>
                            );
                          })
                      ) : (
                        <></>
                      )}
                      {currClass.changes ? (
                        currClass.changes.map((change) => {
                          return change.ChangeType.startsWith("@") ? (
                            <p
                              style={{ margin: 0 }}
                              className="change-text text-end change-text-box"
                              key={currClass.Hour + change.ChangeType.substr(1)}
                            >
                              {change.ChangeType.substr(1)}
                            </p>
                          ) : (
                            <></>
                          );
                        })
                      ) : (
                        <></>
                      )}

                      {currClass.Lessons.map((lesson) => {
                        if (!lesson.Subject)
                          return (
                            <div
                              key={
                                lesson.Subject +
                                ":" +
                                currClass.Hour +
                                ":" +
                                lesson.Teacher
                              }
                            ></div>
                          );

                        return (
                          <div
                            key={
                              lesson.Subject +
                              ":" +
                              currClass.Hour +
                              ":" +
                              lesson.Teacher
                            }
                          >
                            <p
                              style={{ margin: 0 }}
                              className="subject-text text-end"
                            >
                              {lesson.Subject}
                            </p>
                            <p className="teacher-text text-end">
                              {lesson.Teacher
                                ? "(" + lesson.Teacher.trim() + ")"
                                : ""}
                            </p>
                          </div>
                        );
                      })}
                    </td>
                  ) : (
                    <td key={j++}></td>
                  )
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Schedule;
