import NavItemComp from "./NavItemComp";

function NavItemContainer(props) {
  const { items } = props;

  return (
    <div className="collapse navbar-collapse rtl me-auto" id="collapsedNav">
      <ul className="navbar-nav">
        {items.map((item) => (
          <NavItemComp
            to={item.to}
            text={item.text}
            key={item.text.replace(/\s/g, "")} // Remove spaces: "Hello World" -> "HelloWorld"
          />
        ))}
      </ul>
    </div>
  );
}

export default NavItemContainer;
