import "../../css/not-found.css";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="vh-center">
      <p className="display-2 text-center">404 Not found</p>
      <p className="heebo rtl text-center">
        אופס! לא הייתם אמורים לראות את הדף הזה.
        <br />
        אתם בטוחים שהקלדתם את הקישור הנכון?
      </p>

      <Link
        to="/"
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%)",
        }}
      >
        <button
          className="mt-3 btn btn-dark heebo"
          style={{ width: "fit-content" }}
        >
          בחזרה לאתר
        </button>
      </Link>
    </div>
  );
}

export default NotFound;
