import { useParams, useOutletContext } from "react-router-dom";
import ListBox from "./ListBox";
import Schedule from "./Schedule";
import "../../../css/grades.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import Loading from "../../common/Loading";
import url from "../../../utils/url";

const baseURL = url;

function fetchGradeData(grade, classNum) {
  return axios.get(`classes/schedule/${grade}/${classNum}`, { baseURL });
}

function Grade(props) {
  const { id } = useParams();
  const [currClass, setCurrClass] = useState();
  const [nearTests, setNearTests] = useState();
  const [nearEvents, setNearEvents] = useState();
  const [schedule, setSchedule] = useState();
  const [gradeClasses, setGradeClasses] = useState();
  const [classes, setClasses] = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !currClass ||
      (currClass.includes(":") && currClass.split(":")[1] !== id)
    ) {
      const cls = classes.filter((cls) => cls.Grade === Number(id));
      setGradeClasses(cls);
      console.log(classes);
      setCurrClass(cls[0].Number + ":" + cls[0].Grade);
      return;
    }
    (async () => {
      const gradeData = (await fetchGradeData(id, currClass.split(":")[0]))
        .data;
      setNearEvents(gradeData["data"]["events"]);
      setSchedule(gradeData["data"]["schedule"]);
      setNearTests(gradeData["data"]["exams"]);
    })();
  }, [classes, currClass, id]);

  if (id < 7 && id > 12) return <></>;

  if (!gradeClasses || !schedule) return <></>;

  function selectGrade(cls) {
    setCurrClass(cls);
  }

  return (
    <div className="container">
      <hr className="small-line" />
      <div className="row">
        <ListBox title="אירועים קרובים" list={nearEvents} />
        <ListBox title="מבחנים קרובים" list={nearTests} />
      </div>
      <hr className="small-line" />
      <div className="w-100 p-4">
        <div className="d-flex justify-content-center position-relative align-item-center w-100">
          <p className="title text-center">מערכת שעות</p>
          {!currClass ? (
            <Loading />
          ) : (
            <div>
              <select
                className="rtl dropdown-select position-absolute end-0 d-none d-lg-block"
                style={{ top: "calc(50% - 25px)" }}
                onChange={(e) => selectGrade(e.target.value)}
              >
                {gradeClasses.map((cls) => (
                  <option
                    key={cls.Id + ":" + cls.Name}
                    value={cls.Number + ":" + cls.Grade}
                  >
                    {cls.Name}
                  </option>
                ))}
              </select>
              <select
                className="rtl dropdown-select position-absolute end-0 d-lg-none"
                onChange={(e) => selectGrade(e.target.value)}
              >
                {gradeClasses.map((cls) => (
                  <option
                    key={cls.Id + ":" + cls.Name}
                    value={cls.Number + ":" + cls.Grade}
                  >
                    {cls.Name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <Schedule schedule={schedule} exams={nearTests} />
      </div>
    </div>
  );
}

export default Grade;
