import "../../css/loading.css";
import { ReactComponent as LoadingSvg } from "../../static/loading-dark.svg";

function Loading(props) {
  return (
    <div
      className={
        "d-flex justify-content-center" +
        (props.ignoreAbs ? "" : " load-container")
      }
    >
      <div className="align-self-center" role="status">
        <LoadingSvg style={{ maxHeight: 80 }} />
      </div>
    </div>
  );
}

export default Loading;
