import "../../../css/grades.css";
import Loading from "../../common/Loading";

function ListBox(props) {
  let list = props.list;

  function getDateFromString(dateString) {
    let date = new Date(Number(dateString.substring(6, dateString.length - 2)));
    return Intl.DateTimeFormat("he-IL", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    }).format(date);
  }

  return (
    <div className="col-md">
      <p className="title text-center">{props.title}</p>
      <div className="blue-box">
        {list ? (
          list.length !== 0 ? (
            list.map((x) => (
              <div
                key={`${x.Name};${x.Date};${
                  x.StudyGroup ? x.StudyGroup.Subject : x.Room
                }`}
              >
                <p className="title-in-box">{x.Name}</p>
                <p className="date-in-box">{getDateFromString(x.Date)}</p>
                {list[list.length - 1] === x ? <></> : <hr />}
              </div>
            ))
          ) : (
            <div className="h-100 d-flex align-content-center justify-content-center date-in-box">
              <p style={{ marginBottom: "0", margin: "10px" }}>
                אין תוכן להציג
              </p>
            </div>
          )
        ) : (
          <Loading ignoreAbs />
        )}
      </div>
    </div>
  );
}

export default ListBox;
