import React from "react";
import { PieChart } from "react-minimal-pie-chart";

function getCharts() {
  return [
    {
      title: "זכאות לבגרות",
      legend: ["98.4% זכאות"],
      id: 1,
      data: [
        { value: 98.4, color: "#254569" },
        { value: 1.6, color: "#1663a7" },
      ],
    },
    {
      title: '5 יח"ל מתמטיקה',
      legend: ['44.3% מהתלמידים סיימו מתמטיקה 5 יח"ל'],
      id: 2,
      data: [
        { value: 44.3, color: "#254569" },
        { value: 55.7, color: "#1663a7" },
      ],
    },
    {
      title: 'שרות לאומי וגיוס לצה"ל',
      legend: ["95.5% גיוס או שרות לאומי"],
      id: 3,
      data: [
        { value: 95.5, color: "#254569" },
        { value: 4.5, color: "#1663a7" },
      ],
    },
  ];
}

function Statistics() {
  const charts = getCharts();

  return (
    <>
      {charts.map((chart) => {
        return (
          <div className="chart-container rtl col-md" key={chart.id}>
            <div className="row">
              <p className="chart-title">{chart.title}</p>
              <div className="col-xxl-6">
                <PieChart className="pchart" data={chart.data} animate />
              </div>
              <div className="col-xxl m-auto">
                {chart.legend.map((line) => (
                  <p className="stats-legend" key={line}>
                    {line}
                  </p>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Statistics;
