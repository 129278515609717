import "./App.css";
import Home from "./components/routes/Home";
import NotFound from "./components/routes/NotFound";
import Footer from "./components/common/Footer";
import Navbar from "./components/common/navbar/Navbar";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import Error from "./components/routes/Error";
import Grade from "./components/routes/grades/Grade";

function App() {
  const Gallery = lazy(() => import("./components/routes/Gallery/Gallery"));
  const Grades = lazy(() => import("./components/routes/grades/Grades"));
  const NewStudents = lazy(() =>
    import("./components/routes/newstudents/NewStudents")
  );
  const PostComp = lazy(() => import("./components/routes/posts/PostComp"));
  const Posts = lazy(() => import("./components/routes/posts/Posts"));
  const Elective = lazy(() => import("./components/routes/Elective"));
  const OurValues = lazy(() => import("./components/routes/OurValues"));
  const ModernLearning = lazy(() =>
    import("./components/routes/ModernLearning")
  );

  function getLazyLoaded(LazyComponent) {
    return (
      <Suspense fallback={<p className="rtl">טוען...</p>}>
        <LazyComponent />
      </Suspense>
    );
  }

  const { pathname } = useLocation();
  const noFooter = ["/error", "/404"].includes(pathname);

  return (
    <React.Fragment>
      <Navbar stillColor={pathname === "/"} />
      <main className="min-vh-100">
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="gallery" element={getLazyLoaded(Gallery)} />
          <Route path="grades" element={getLazyLoaded(Grades)}>
            <Route path=":id" element={getLazyLoaded(Grade)} />
          </Route>
          <Route path="new-students" element={getLazyLoaded(NewStudents)} />
          <Route path="posts/:postID" element={getLazyLoaded(PostComp)} />
          <Route path="posts" element={getLazyLoaded(Posts)} />
          <Route path="elective" element={getLazyLoaded(Elective)} />
          <Route path="our-values" element={getLazyLoaded(OurValues)} />
          <Route
            path="modern-learning"
            element={getLazyLoaded(ModernLearning)}
          />
          <Route path="error" element={<Error />} />
          <Route path="404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="404" replace />} />
        </Routes>
      </main>
      {noFooter ? null : <Footer />}
    </React.Fragment>
  );
}

export default App;
