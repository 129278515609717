import { NavLink } from "react-router-dom";

function NavItemComp(props) {
  return (
    <li className="nav-item ms-3">
      <NavLink className="nav-link" to={props.to}>
        {props.text}
      </NavLink>
    </li>
  );
}

export default NavItemComp;
