import { useEffect } from "react";
import { Link } from "react-router-dom";
import "../../css/home.css";
import BuildingWebp from "../../static/building.webp";
import ScrollSvg from "../../static/scroll.svg";
import TeamWebp from "../../static/team.webp";
import TeamSmWebp from "../../static/team-sm.webp";
import Statistics from "../Statistics";

function Home(props) {
  useEffect(() => {
    document.title = "תיכון גבעת שמואל";
  }, []);

  return (
    <div className="container-lg">
      <div className="landing-area">
        <div className="row">
          <div className="col-lg d-none d-lg-block">
            <div className="showcase-parent">
              <img className="building-showcase" src={BuildingWebp} alt="מבנה התיכון" />
            </div>
          </div>
          <div className="col-lg-9 align-self-center">
            <p className="home-title rtl">תיכון גבעת שמואל</p>
            <p className="home-subtitle rtl">תיכון למצוינות בתחום החינוך</p>
            <p className="home-p rtl">
              בית ספר ממלכתי השואף למצוינות ודוגל בערבות הדדית וביחס מכבד.
            </p>
          </div>
        </div>

        <div className="scroll-info">
          <a href="#statistics">
            <img src={ScrollSvg} alt="Scroll" style={{ height: "50px" }} />
          </a>
        </div>
      </div>
      <hr />
      <div className="section-1" id="statistics">
        <p className="section-title text-center">קצת עלינו בנתונים</p>
        <div className="s1-stats row">
          <Statistics />
        </div>
      </div>
      <hr />

      <div className="section-2" id="subjects">
        <p className="section-title text-end m-0">המגמות שלנו</p>
        <p className="subjects-paragraph rtl">
          יש מגוון של מגמות בתיכון, מגמות מדעיות, אומניות וכו'. אנו מאמינים שתהליך בחירת המגמות
          מהווה חלק חשוב מאוד לתלמידים ולעתידם.
          <br />
          המגמות בתיכון מאפשרות לתלמידים בחירה לכל דרך בה ירצו ללכת בחיים: הדרך הטכנולוגית,
          האומנותית ואולי אפילו שילוב של שתיהן!
          <br />
          <br />
          <em>העתיד בידי התלמידים.</em>
        </p>
        <Link to="subjects" className="text-end d-flex flex-row-reverse text-decoration-none mt-5">
          <button className="btn subject-list-btn">לרשימת המגמות</button>
        </Link>
      </div>
      <hr />
      <div className="section-3" id="team">
        <div className="row flex-lg-row align-items-center g-5 py-5 m-auto">
          <div className="col-10 col-sm-8 col-lg-6 m-auto">
            <img
              src={TeamWebp}
              className="d-block mx-lg-auto img-fluid d-none d-lg-block"
              style={{ height: "50 !important" }}
              alt="צוות התיכון"
              loading="lazy"
            />
            <img
              src={TeamSmWebp}
              className="d-block mx-lg-auto img-fluid d-lg-none"
              alt="צוות התיכון"
              loading="lazy"
            />
          </div>
          <div className="col-lg-6 rtl heebo text-center">
            <h1 className="display-5 fw-bold lh-1 mb-3">צוות התיכון</h1>
            <p className="lead">
              מרגע הקמת התיכון התחלנו לבחור את צוות המורים המקצועיים בקפידה. אנחנו מאמינים שהכי חשוב
              שמורה קודם כל יגרום לתלמידים להרגיש בנוח בכיתה, ורק משם הלמידה עצמה תתחיל. זהו היבט
              היסוד על פיו אנו פועלים בתהליך בחירת הצוות החינוכי.
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div className="section-4" id="statistics">
        <p className="section-title text-center">מנהלת התיכון - חנית אלון</p>
        <div className="promo-vid">
          <iframe
            src="https://www.youtube.com/embed/00pFBQ7rlpo?color=white"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Home;
